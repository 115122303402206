import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Foot, Float, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/fitri.mp3'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/burung-white.svg'
import bunga6 from '../assets/img/bunga6.png'
import covid from '../assets/img/fitri/covid.svg'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import getData from '../params/getdata'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#413C42'
let mainfont = 'white'
let black = 'rgb(38,38,38)'
export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()
    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: true,
      err: [],
      submitted: '',
      sesi: 2,
      showgift: false, show: false, show1: false,
      comment:[]
    }
  }
  async componentDidMount() {
    try {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    var countDownDate = new Date("12/18/2020").getTime();
    // Update the count down every 1 second
    var x = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);
    
    let comment=await getData('kepada:"fitri-ralo"')
    
      this.setState({ comment: comment })
    } catch (error) {
      console.log(error)
    }
  }

  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/mp3';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)

   
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "fitri-ralo", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi, showgift, show, show1,comment } = this.state
    let slide = ["	https://i.ibb.co/xgk1PnD/CND-9235.jpg	",
      "	https://i.ibb.co/ngQGQ5J/CND-9297.jpg	",
      "	https://i.ibb.co/RQ0WXgx/CND-9305.jpg	",
      "	https://i.ibb.co/W0WrRLX/CND-9307.jpg	",
      "	https://i.ibb.co/kqwDrty/CND-9317.jpg	",
      "	https://i.ibb.co/SydfQ64/DSCF4841.jpg	",
      "	https://i.ibb.co/tXfSk1B/DSCF4892.jpg	",
      "	https://i.ibb.co/TgsHqmC/Whats-App-Image-2020-11-20-at-9-09-03-PM-1.jpg	",
      "	https://i.ibb.co/nj2PDwj/Whats-App-Image-2020-11-20-at-9-09-03-PM.jpg	",
      "	https://i.ibb.co/KF7jSDc/Whats-App-Image-2020-11-20-at-9-09-04-PM.jpg	",
    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
    })
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''
    let story = [
      "	https://i.ibb.co/T2qKtDH/2.jpg	",
      "	https://i.ibb.co/Nr6J8VK/3.jpg	",
      "	https://i.ibb.co/1TJCDxD/4.jpg	",
      "	https://i.ibb.co/6wzHBHw/5.jpg	",
      "	https://i.ibb.co/kJyJZz2/1.jpg	",

    ]
    let caption = [
      `Perkenalan singkat dengan cerita yang tak begitu berkelit, adalah awal kisah ini dimulai. Sangat mudah meyakinkan diri terhadap jodoh yang ditakdirkan Allah SWT.
      `,
      `Hati ini menemukan dermaga yang tepat untuk berlabuh, rumah yg nyaman untuk pulang dan pasangan yg cocok untuk menata masa depan. 
      `,
      `Dari semua yang pernah datang, mengajarkan cara menggenggam tangan. Sedang, dari semua yang memilih pergi, memberikan arti memiliki. Memilihnya bukan karena dia sempurna, namun perasaan saling melengkapi membuat ini jauh lebih baik. 
      `,
      `Entah dari mana datangnya, Tuhan mempertemukan kami dengan cara yang unik. Sebuah cerita yang akan kami ingat seumur hidup. Memang tak seindah drama korea, namun kami berharap menemukan surga di akhir cerita.
      `, `
      Agar cinta tak berhenti sebagai wacana, agar harapan tak kandas di tengah jalan, 
      Mungkin bisa dimulai dari cincin yang seharusnya melingkar d jari. Menyatukan dua keluarga dalam ikatan pernikahan,  adalah jalan yang kami pilih.
 `




    ]
    return (
      <>
        <Helm
          title='Undanganku - Fitri & Ralo'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={'https://i.ibb.co/xgk1PnD/CND-9235.jpg'}
          url='https://undanganku.me/fitri-ralo'
        />

        <div id='gold5' style={{
          backgroundColor: cmain
        }}>
          {
            this.useQuery().get('x')=="x"?(<Float/>):false
          }
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw',
            backgroundColor: 'transparent'
          }}>
            <Container fluid id='g3-header' className='position-relative' style={{
              backgroundImage: "none",
              backgroundColor: cmain
            }}>
              <Col md={2} xs={5} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="img-fluid w-100" />
              </Col>
              <Col md={2} xs={5} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="mirror img-fluid w-100" />
              </Col>
              <Col md={2} xs={5} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="flip180-m img-fluid w-100" />
              </Col>
              <Col md={2} xs={5} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className=" flip180  img-fluid w-100" />
              </Col>

              <Item>
                <Col xs={12} md={4} className='m-2 m-md-0 '>
                  <img className='img-fluid w-100 p-2'
                    src={'https://i.ibb.co/PhzJnhT/logofitri.png'} data-aos="fade-left" />
                </Col>
              </Item>
              <Item>
                {
                  <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-md-3`} style={{ marginTop: '0', color: 'white' }}>
                    Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                }</Item>
              <Row className='justify-content-center'>
                <div onClick={() => { this.play() }}
                  className={`col-md-4 button btn roboto-slab btn text-center ${hide ? 'show' : 'hide'}`}
                  style={{ marginTop: 0, color: 'white', borderColor: 'white' }}>
                  Open Invitation
                </div>
              </Row>
            </Container>

            <div className={hide ? 'd-none' : 'd-block'}>
              <div id="top" style={{ backgroundColor: 'transparent' }}>
                <Container className="dinny px-3 pt-5 ">
                  <Item>
                    <p className="fs24 text-center px-3" >
                      <span className="fs24" style={{ color: mainfont, fontFamily: 'Patrick Hand, cursive' }}>
                        Tuhan membuat segala sesuatu indah pada waktu-Nya, Indah saat mempertemukan, Indah saat Dia menumbuhkan kasih, dan Indah saat Dia mempersatukan dalam suatu ikatan perkawinan
                        </span>
                    </p>
                  </Item>
                  <Item>
                    <Col xs={6} sm={2}>
                      <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center p-2 px-4 fs24' style={{ color: mainfont, fontFamily: 'Patrick Hand, cursive' }}>
                      The Intimate Wedding of <br />Fitri & Ralo
                              </p>
                  </Item>
                </Container>
                <Container id='sectiongold55' className="py-5 dinny" >

                  <Item>
                    <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{
                            fontSize: '72px', fontFamily: 'Parisienne, cursive',
                            color: cmain
                          }}>
                            Fitri
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/6XRF7zy/DSCF4892-1.jpg', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{
                            fontSize: '28px',
                            fontFamily: 'Parisienne, cursive',
                            color: cmain
                          }}>
                            Fitri Wulandari, S.Pd
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: cmain }}>
                            <b>The Daughter of: </b><br />
                            Bpk H. Ridwan Irianto  <br />
                        &<br />
                        Ibu Hj. Siti Aminah
                      </p>
                        </Item>
                        <Item>
                          <img src={logoig} className='btn mr-2 p-0' style={{ width: '35px' }}
                            onClick={() => { window.open('https://instagram.com/mba.moon') }} />
                          <img src={'https://www.flaticon.com/svg/static/icons/svg/145/145802.svg'}
                            className='btn ml-2 p-0' style={{ width: '35px' }}
                            onClick={() => { window.open('https://www.facebook.com/fie.wulanddari') }} />
                        </Item>
                      </div>
                    </div>
                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: 'Parisienne, cursive', color: cmain }}>
                            Ralo
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/N94d191/CND-9297-1.jpg', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{
                            fontSize: '28px',
                            fontFamily: 'Parisienne, cursive', color: cmain
                          }}>
                            Ralo Anggalo, S.Hut
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: cmain }}>
                            <b>The Son of : </b><br />
                            Bpk Patjri
                        <br />
                        &<br />
                        Ibu Lilis Suryani
                      </p>
                        </Item>
                        <Item>
                          <img src={logoig} className='btn mr-2 p-0' style={{ width: '35px' }}
                            onClick={() => { window.open('https://instagram.com/Ralo_anggalo') }} />
                          <img src={'https://www.flaticon.com/svg/static/icons/svg/145/145802.svg'}
                            className='btn ml-2 p-0' style={{ width: '35px' }}
                            onClick={() => { window.open('https://www.facebook.com/ralo.anggalo') }} />
                        </Item>
                      </div>
                    </div>
                  </Item>
                </Container>
                <Container fluid className="text-center px-4 dinny" style={{ color: mainfont, fontFamily: 'Patrick Hand, cursive' }} >
                  <Item>
                    <p className="fs20" >
                      Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                  </Item>
                  <Item>
                    <p className="fs24">
                      <b>
                        JUMAT <span className="fs36">18</span> DES 2020
                      </b>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs24 col-sm-4" style={{ color: mainfont }}>
                      <b>Akad Nikah </b><br />
                      <span className=" fs20">
                        09.00 WIB - Selesai
                      </span><br />

                    </p>
                    <p className="px-3 d-none d-sm-block" style={{ color: mainfont, fontSize: '72px' }}>
                      \
                    </p>
                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                    </div>
                    <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: mainfont }}>
                      <b>Resepsi</b><br />
                      <span className=" fs20">
                        13.30 WIB - Selesai
                      </span>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs16 pt-3 w-100 text-center">
                      Jl. Palembang Jambi Desa senawar jaya rt 08 rw 01 kecamatan bayung lencir<br />
 Kab. Musi Banyuasin, Sumatera Selatan

                    </p>

                  </Item>
                  <Item>
                    <Col xs={10} sm={3}
                      style={{
                        border: `2px solid ${mainfont}`,
                        borderRadius: '10px'
                      }}
                      onClick={() => {
                        window.open("https://www.google.com/maps?q=-1.9842689,103.6889357&z=17&hl=en")
                      }}
                      className="p-2 mx-sm-2 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: mainfont }}>
                          <b>Get Direction</b>
                        </p>
                      </Item>
                    </Col>
                    <Col
                      onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=MmVibDl2bGRvZW91NTQybzRwNThwZjJxdGkgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                      xs={10} sm={3}
                      style={{
                        border: `2px solid ${mainfont}`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: mainfont }}>
                          <b>Add to Calendar</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container id='sectiongold57'>
                  <div className='py-3'>
                    <Item>
                      <div data-aos="fade-left" data-aos-duration="1000"
                        className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                        <Item>
                          <div className='item'>
                            <Item>
                              <div>
                                {days}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Days
                                </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {hours}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Hours
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div >
                                {minutes}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Mins
                      </span>
                            </Item>
                          </div>
                          <div className='dot' >:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {seconds}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Secs
                      </span>
                            </Item>
                          </div>
                        </Item>

                      </div>
                    </Item>
                  </div></Container>
                {/* <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: mainfont
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className=" text-center w-100 fs16" style={{color:mainfont}}>
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className=" text-center w-100 fs16" style={{color:mainfont}}>
                              Gunakan Masker
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className=" text-center w-100 fs16" style={{color:mainfont}}>
                              jaga Jarak
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className=" text-center w-100 fs16" style={{color:mainfont}}>
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container> */}
                <Container className="py-3">
                  <Item>
                    <Col xs={12} md={6}>
                      <img src={covid} className="w-100 img-fluid" />
                    </Col>
                  </Item>
                </Container>
                <Container className="py-5 px-md-5 col-md-8 px-3"
                  style={{ color: 'white', fontFamily: 'Patrick Hand, cursive' }}
                >

                  <Row className="position-relative px-5 py-5" style={{ backgroundColor: 'rgba(255,255,255,0.1)' }}>
                    <h1 className="peri w-100 text-center">
                      Our Love Story
                  </h1>
                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="mirror img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="flip180-m img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className=" flip180  img-fluid w-100" />
                    </Col>
                    {story.map((v, i) => {
                      return (
                        <Item>
                          <Col xs={12} md={5} className={`${i % 2 ? 'order-md-2' : 'order-md-1'} text-center `}>
                            <img src={gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=500&h=500')} className="img-fluid w-100 p-3" />
                          </Col>
                          <Col xs={12} md={7} className={i % 2 ? 'order-md-1' : 'order-md-2'} >
                            <Row className="h-100">
                              <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                " {caption[i]} "
                            </p>
                            </Row>
                          </Col>
                        </Item>
                      )
                    })}
                  </Row>
                </Container>


                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                  <Slider slide={slider} />
                </Container>
                <Container id='sectiongold56'>
                  <div className='pt-3'>

                    <div data-aos={`fade-right`} data-aos-duration="2000">
                      <Item>
                        <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                          <Item>
                            <p className='text-center p-2 px-4' style={{ fontFamily: 'Patrick Hand, cursive' }}>
                              Whatever our souls are made of, his and mine are the same. <br />- Emily Brontë -
                              </p>
                          </Item>
                        </div>

                      </Item>
                    </div>
                  </div>
                </Container>
                <Container className="py-3">
                  <Item>
                    <Col
                      onClick={() => this.setState({ showgift: !showgift })}
                      xs={10} md={4}
                      style={{
                        border: `2px solid white`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-md-2 mt-3 mt-md-0 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                          <b>Send Gift (Klik Disini)</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container fluid
                  className={`text-center mt-3 aulia-wedding py-3 px-3 ${!showgift ? 'trsnone' : 'trs'}`} >
                  <Item>
                    <Col className="py-3 px-md-5 mx-2" style={{ backgroundColor: cmain, borderRadius: '10px', border: '2px solid white' }}>
                      <h1>
                        Wedding Gift
                      </h1>
                      {/* <Item>
                            <Col xs={8} md={4}>
                              <img src='https://i.ibb.co/5K53bBy/QR-BCA.png' className='p-2 w-100 img-fluid'/>
                            </Col>
                          </Item> */}
                      <Item>
                        <p className="text-center w-100">
                          BNI - 0435550325 <br />
                            (Ralo Anggalo)
                          </p>
                        <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                          <Toast.Body>Copied!</Toast.Body>
                        </Toast>
                        <CopyToClipboard text="0435550325">
                          <Col xs={8} md={5} className='btn button mb-5 mt-1'
                            onClick={(e) => {
                              this.setState({ show: true })

                            }}>
                            Salin No. Rekening
                          </Col>
                        </CopyToClipboard>
                        <p className="text-center w-100">
                          Bank Sumsel Babel - 01760910219
                        <br />
                            (Fitri Wulandari)
                          </p>
                        <Toast onClose={() => this.setState({ show1: false })} show={this.state.show1} delay={3000} autohide >
                          <Toast.Body>Copied!</Toast.Body>
                        </Toast>
                        <CopyToClipboard text="01760910219">
                          <Col xs={8} md={5} className='btn button mb-5 mt-1'

                            onClick={(e) => {

                              this.setState({ show1: true })
                            }}>
                            Salin No. Rekening
                          </Col>
                        </CopyToClipboard>
                        <p className="text-center w-100">
                          Alamat Pengiriman Kado
                          </p>
                        <p className="text-center w-100 px-3">
                          <b>
                            Perumahan Puri Tampak Siring No. 17 RT 36, Kel. Kenali Asam Bawah, KOTA JAMBI, Kec. KOTA BARU, JAMBI,  36129
                            </b>
                        </p>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container>

                <Container id='sectiongold58' >

                  <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                    <Item>
                      <Col xs={4} lg={2}>
                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                        <Item>
                          <h1 style={{
                            fontFamily: '"Marck Script", cursive',
                            color: cmain
                          }}>
                            Send Your Wishes
                     </h1>
                        </Item>
                        <Item>
                          <form className="col-12 w-100">
                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                            <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1">Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"

                                    >Tidak Hadir</label>
                                  </div>

                                </div>
                              </div>
                            </Item>

                            <Item>
                              <Col xs={12} className=''>
                                {
                                  submitted == true ? (
                                    <Alert variant='success' style={{ fontSize: '16px' }}>
                                      Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                      <Alert variant='danger' style={{ fontSize: '16px' }}>
                                        {
                                          err.map(val => {
                                            return (
                                              <li>{val}</li>
                                            )
                                          })
                                        }

                                      </Alert>
                                    ) : false)
                                }

                              </Col>
                            </Item>
                            <Item>
                              <div className='col-6 button rounded btn'
                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                            </Item>
                          </form>
                        </Item>
                      </div>
                    </Item>
                  </div>
                </Container>
                <Container className="py-5 px-md-5 col-md-8 px-3"
                  style={{ color: 'white', fontFamily: 'Patrick Hand, cursive' }}
                >

                  <Row className="position-relative px-5 py-5" style={{ backgroundColor: 'rgba(255,255,255,0.1)' }}>
                    <h1 className="peri w-100 text-center">
                      Ucapan dan doa
                  </h1>
                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="mirror img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="flip180-m img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className=" flip180  img-fluid w-100" />
                    </Col>
                    <Row style={{maxHeight:'50vh',overflowY:'scroll'}} className="scroller">
                    {comment.map((v, i) => {
                      return (
                        <Row className="w-100 text-center mx-auto">
                          <Col></Col>
                            <Col xs={12} md={6} className="py-2">
                              <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                " {v.pesan} " <br/> - {v.dari} - 
                              </p>
                            </Col>
                            <Col></Col>
                        </Row>
                      )
                    })}
                      </Row>
                    
                  </Row>
                  
                </Container>

                <Container classname="py-3" style={{ color: 'white' }}>
                  <p className="text-center w-100" style={{ fontFamily: 'Patrick Hand, cursive' }} >
                    Best Regards
                    </p>
                  <h1 className="text-center w-100" style={{ fontFamily: 'Parisienne, cursive', }}>
                    Fitri & Ralo
                    </h1>
                </Container>

                <Foot ig={logoig} />
              </div>
            </div>
          </div>
        </div>

      </>


    )
  }
}

